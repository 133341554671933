import React from 'react';
import { graphql } from 'gatsby';
import { getImageUrl } from 'takeshape-routing';

import { friendlyDate } from '../lib/datetime';

import Layout from '../layouts/default';

const PostTemplate = ({ data }) => {
  const post = data?.takeshape?.getPost || {};
  let postImg = 'https://placekitten.com/1400/800';
  if (post.featuredImage !== null) {
    postImg = getImageUrl(post.featuredImage.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  }
  return (
    <Layout>
      <article className="blogPost">
        <header className="blogPost--header">
          <div className="blogPost--headerImage masthead x rel">
            <img
              className="x y fill"
              alt={post.title}
              src={postImg}
            />
          </div>
          <div className="blogPost--headerCopy">
            <h1>{ post.title }</h1>
            {post.author.name !== null && (
              <span className="blogPost--byLine">
                By
                {' '}
                { post.author[0].name }
                {' | '}
                <time dateTime={post._enabledAt}>{ friendlyDate(post._enabledAt) }</time>
              </span>
            )}
            {/*
                No Tags For Right Now
                { post.tags?.length > 0 && (
                <p className="postTags">
                  Tags:
                  {' '}
                  { post.tags.map((tag, i) => {
                    let string = '';
                    if (i !== 0) string = `${string}, `;
                    return `${string}${tag.title}`;
                  })}
                </p>
                )}
              */}
          </div>
        </header>

        <div className="blogPost--body">
          <div
            className="blogPost--copy rte"
            dangerouslySetInnerHTML={{
              __html: post.bodyHtml,
            }}
          />
        </div>
      </article>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($postId: ID!) {
    takeshape {
      getPost( _id: $postId ) {
        _id
        _contentTypeName
        _enabledAt
        title
        deck
        author {
          name
        }
        tags {
          title
        }
        featuredImage {
          path
        }
        bodyHtml
      }
    }
  }
`;
